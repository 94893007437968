var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Chat mit " + _vm._s(_vm.guestDisplayName))]),
      _vm._l(_vm.chatMessages, function(chatMessage) {
        return _c(
          "CRow",
          { key: chatMessage.id, class: _vm.rowClassName(chatMessage) },
          [
            _c(
              "CCol",
              { attrs: { md: "6" } },
              [
                _c(
                  "CCard",
                  { class: { isUnread: chatMessage.isRead === false } },
                  [
                    _c(
                      "CCardHeader",
                      [
                        _c("FormatedTimestamp", {
                          attrs: {
                            date: _vm.parseDate(chatMessage.createdAt),
                            displayFormat: "P | p"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "CCardBody",
                      { staticStyle: { "white-space": "pre-line" } },
                      [
                        chatMessage.sender != "System"
                          ? [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(chatMessage.messageText)
                                }
                              })
                            ]
                          : _vm._e(),
                        chatMessage.sender == "System"
                          ? [
                              _c(
                                "CRow",
                                [
                                  _c("CCol", { attrs: { md: "10" } }, [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          chatMessage.messageText
                                        )
                                      }
                                    })
                                  ]),
                                  _c(
                                    "CCol",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("CButton", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.host.HostChatMessageDetail.toOrder",
                                            expression:
                                              "'pages.host.HostChatMessageDetail.toOrder'"
                                          }
                                        ],
                                        attrs: {
                                          color: "primary",
                                          to: {
                                            name: "OwnerOrderDetail",
                                            params: {
                                              orderId: chatMessage.orderId
                                            }
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "CRow",
                                { staticClass: "order-row" },
                                [
                                  _c(
                                    "CCol",
                                    { attrs: { md: "4" } },
                                    [
                                      _vm._v("Aktueller Status: "),
                                      _c("OrderStatus", {
                                        attrs: {
                                          status: chatMessage.orderStatus
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  chatMessage.orderCheckInAt
                                    ? _c("CCol", { attrs: { md: "3" } }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "pages.host.HostChatMessageDetail.orderCheckIn",
                                                {
                                                  dateTime: _vm.formatIsoDateTimeTz(
                                                    chatMessage.orderCheckInAt,
                                                    "P | p"
                                                  )
                                                }
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  chatMessage.orderCheckOutAt
                                    ? _c("CCol", { attrs: { md: "3" } }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "pages.host.HostChatMessageDetail.orderCheckOut",
                                                {
                                                  dateTime: _vm.formatIsoDateTimeTz(
                                                    chatMessage.orderCheckOutAt,
                                                    "P | p"
                                                  )
                                                }
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  _c("CCol", { attrs: { md: "2" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "pages.host.HostChatMessageDetail.orderId",
                                            {
                                              orderId: chatMessage.shortOrderId
                                            }
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "CCard",
        { staticClass: "new-message-card" },
        [
          _c(
            "CCardBody",
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { md: "9" } },
                    [
                      _c("ValidatedTextArea", {
                        attrs: {
                          rows: "10",
                          field: _vm.$v.message.messageText,
                          "data-cy": "messageText-field",
                          value: _vm.$v.message.messageText.$model,
                          translationKey: "message.messageText"
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.message.messageText,
                              "$model",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "CButton",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            value: "Speichern",
                            color: "primary",
                            "data-cy": "submit",
                            disabled: _vm.isSubmitButtonDisabled
                          },
                          on: { click: _vm.handleSubmit }
                        },
                        [
                          _vm.sending
                            ? _c("CSpinner", { attrs: { size: "sm" } })
                            : _vm._e(),
                          _vm._v(" Senden ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }