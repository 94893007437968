var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CBadge", { attrs: { color: _vm.color } }, [
    _vm._v(" " + _vm._s(_vm.$t("enums.orderStatus." + _vm.status)) + " ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }